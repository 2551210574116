header {
  border-bottom: 1px solid #ccc;
  padding: 0 15px;
  @media #{$middle} {
    border-bottom: 1px solid #ccc;
    padding: 15px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
    background-color: #fff;
  }
  @media #{$large} {
    border-bottom: 1px solid #ccc;
    padding: 15px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
    background-color: #fff;
  }

  h1 {
    font-size: 10px;
    margin: 5px 0 0 15px;
    @media #{$middle} {
      margin: 0 0 0 15px;
    }
    @media #{$large} {
      margin: 0 0 0 15px;
    }
  }

  div.headerH1 {
    font-size: 10px;
    margin: 5px 0 0 15px;
    @media #{$middle} {
      margin: 0 0 0 15px;
    }
    @media #{$large} {
      margin: 0 0 0 15px;
    }
  }


  .navbar-brand {
    padding: 5px 0 0 0;
  }
  .navbar-brand img {
    width: 150px;
    margin: 0 0 0 15px;
    @media #{$middle} {
      width: 200px;
    }
    @media #{$large} {
      width: 200px;
    }
  }

  .navbar-collapse {
    padding-right: 0;
    padding-left: 0;
  }

  .site_logo {
    text-align: left;
    @media #{$middle} {
      text-align: left;
      margin: 0;
    }
    @media #{$large} {
      text-align: left;
      margin: 0;
    }
  }


  .head_menu {
    text-align: left;
  }

  .navbar-inverse {
    background-color: #fff;
    border-color: #ccc;
  }

  .site_logo {
    display: block;
    margin-top: 15px;
    margin-left: 15px;
  }

  .navbar-inverse .navbar-toggle {
    border-color: #E64B1E;
  }

  .navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-top: 8px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: $base-color;
    border: 1px solid transparent;
    border-radius: 4px;
  }

  .navbar-inverse .navbar-toggle:hover {
    background-color: #333;
    border-color: #333;
  }

  .navbar-inverse .navbar-nav > .active > a {
    color: #fff;
    background-color: darken($base-color, 20%);
  }

  .navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
    border-color: #ccc;
    margin-left: -15px;
    margin-right: -15px;
  }

  .navbar-inverse .navbar-nav > .active > a {
    color: #fff;
    background-color: darken($base-color, 20%);
  }

  .navbar-inverse .navbar-nav > li > a:focus, .navbar-inverse .navbar-nav > li > a:hover {
    color: #fff;
    background-color: darken($base-color, 20%);
  }

  .navbar-inverse .navbar-nav > li > a {
    color: #fff;
    @media #{$middle} {
      color: #333;
    }
    @media #{$large} {
      color: #333;
    }
  }

  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 20px;
  }

  .nav > li > a {
    position: relative;
    display: block;
    padding: 10px 15px;
    background-color: darken($base-color, 20%);
    border-bottom: 1px solid #DDDDDD;
    @media #{$middle} {
      font-size: 85%;
      padding: 10px 7px;
      margin: 5px 0 0 0;
      background-color: #fff;
      border-bottom: 1px solid #fff;
    }
    @media #{$large} {
      padding: 10px;
      margin: 5px 0 0 0;
      background-color: #fff;
      border-bottom: 1px solid #fff;
    }
  }


  .navbar {
    position: relative;
    min-height: 50px;
    margin-bottom: 0;
    border: none;
    @media #{$middle} {
      border: 1px solid #fff;
      margin-right: 15px;
    }
    @media #{$large} {
      border: 1px solid #fff;
      margin-right: 15px;
    }
  }



  .navbar-nav {
    margin: 0px 15px;
  }

  .head_menu {
    margin-left: -15px;
    margin-right: -15px;
  }



  .navbar-default {
    @media #{$middle} {
      background-color: #fff;
    }
    @media #{$large} {
      background-color: #fff;
    }
  }


}
