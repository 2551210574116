
section {
  &.archives {

    .panelArchive {
      h4 {
        margin: 0;
        @include fontsize(24);
        @media #{$small}{
          @include fontsize(22);
        }
        a {
          display: block;
          color: $base-color_4;
        }
      }
    }

    .serviceName {
      a {
        display: inline-block;
        background-color: $base-color_4;
        @include border-radius(5px);
        color: #FFF;
        padding: 2px 10px;
        @include fontsize(22);
        @media #{$small}{
          @include fontsize(20);
        }
      }
    }

    ul {
      &.featureServiceList {
        text-align: right;
        li {
          display: inline-block;
          a {
            display: inline-block;
            background-color: $base-color_4;
            @include border-radius(5px);
            color: #FFF;
            padding: 2px 10px;
            @include fontsize(22);
            @media #{$small}{
              @include fontsize(20);
            }
          }
        }
      }
    }

  }
}


.recommendService {
  h3 {
    &.recommendServiceTitle {
      padding: 10px;
      background-color: #EEEEEE;
      border-bottom: 1px solid #CCCCCC;
      font-weight: bold;
      @include fontsize(23);
      @media #{$middle}{
        @include fontsize(26);
      }
      @media #{$large}{
        @include fontsize(26);
      }
    }
  }

  h4 {
    &.recommendServiceListTitle {
      border-left: 5px solid #649a83;
      background-color: #EEEEEE;
      margin: 0 0 5px 0;
      @include fontsize(34);
      @media #{$small}{
        @include fontsize(22);
      }
      a {
        padding: 10px;
        display: block;
        color: #21332b;
        @media #{$small}{
          padding: 5px;
        }
        small {
          @media #{$large}{
            margin-top: 5px;
            margin-right: 10px;
            float: right;
          }
          @media #{$middle}{
            margin-top: 5px;
            margin-right: 10px;
            float: right;
          }
          @media #{$small}{
            margin-top: 5px;
            display: block;
          }
        }
      }
      &:hover {
        background-color: #DDDDDD;
      }
    }
  }


}




.archiveComponents {

}