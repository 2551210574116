
.commentWellBlock {
  background-color: #FFFFFF;
}


#commentForm {


  label {
    &.checkboxList,
    &.radioList {
      font-weight: normal;
      display: block;
      @include fontsize(24);
      @media #{$small}{
        @include fontsize(20);
      }
    }
  }

  .contactTitle {
    display: block;
    font-weight: bold;
    border-bottom: 1px dotted #668fd6;
    &:before {
      font-weight: normal;
      color: #668fd6;
      content: "\f046";
      font-family: FontAwesome;
      padding-right: 10px;
    }
    @include fontsize(24);
    @media #{$small}{
      @include fontsize(20);
    }
  }

  h5 {
    font-weight: 600;
    @include fontsize(20);
    @media #{$small}{
      @include fontsize(18);
    }
  }

  .required {
    color: #ff0000;
  }


  .checkbox, .radio {
    display: inline;
  }


}
