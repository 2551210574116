.balloon1 {
  position: relative;
  display: inline-block;
  margin: 0 0 30px;
  padding: 15px 0;
  min-width: 120px;
  width: 100%;
  color: #555;
  @include fontsize(40);
  @media #{$small} {
    @include fontsize(30);
  }
  font-weight: bold;
  color: #fff;
  background: #007551;
  text-align: center;
}

.balloon1:before{
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -15px;
  border: 15px solid transparent;
  border-top: 15px solid #007551;
}

.balloon1 p {
  margin: 0;
  padding: 0;
  color: #fff;
}