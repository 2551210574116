.pagination{
  list-style-type: none;
  padding-left: 0;
  margin: 30px 0;
}

.pagination,
.pagination li a {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.pagination a {
  font-weight: 300;
  padding-top: 1px;
  text-decoration:none;
  border: 1px solid #ddd;
  border-left-width: 0;
  min-width:36px;
  min-height:36px;
  color: #333;
}

.pagination li:not([class*="current"]) a:hover {
  background-color: #eee;
}

.pagination li:first-of-type a {
  border-left-width: 1px;
}

.pagination li.first span,
.pagination li.last span,
.pagination li.previous span,
.pagination li.next span {
  /* screen readers only */
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.pagination li.first a::before,
.pagination li.last a::after,
.pagination li.previous a::before,
.pagination li.next a::after {
  display: inline-block;
  font-family: Fontawesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}

.pagination li.first a::before { content: "\f100"; }
.pagination li.last a::after { content: "\f101"; }

.pagination li.previous a::before { content: "\f104"; }
.pagination li.next a::after { content: "\f105"; }

.pagination li.current a {
  background-color: #ddd;
  cursor: default;
  pointer-events: none;
}

.pagination > li:first-child > a {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.pagination > li:last-child > a {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

@media only screen and ( max-width: 680px ) {
  .pagination li.first,
  .pagination li.last {
    /* screen readers only */
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .pagination li.previous a { border-left-width: 1px; }
}

@media only screen and ( max-width: 500px ) {
  .pagination li {
    /* screen readers only */
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .pagination li.current,
  .pagination li.first,
  .pagination li.last,
  .pagination li.previous,
  .pagination li.next{
    position: initial;
    top: initial;
    left: initial;
  }

  .pagination li.previous a { border-left-width: 0; }
}

@media only screen and ( max-width: 400px ) {
  .pagination li.first,
  .pagination li.last {
    /* screen readers only */
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .pagination li.previous a { border-left-width: 1px; }
}

@media only screen and ( max-width: 240px ) { /* For watches? */
  .pagination li { width: 50%;}

  .pagination li.current {
    order: 2;
    width: 100%;
    border-left-width: 1px;
  }
}

