.serviceCategory {
  li {
    display: inline-block;
    margin-bottom: 3px;
    a {
      background-color: $base-color_4;
      color: #FFF;
      @include border-radius(5px);
      padding: 3px 10px;
      display: block;
      @include fontsize(20);
      @media #{$small}{
        @include fontsize(15);
      }
    }
  }
}


.serviceEvaluation {

  h4 {
    color: darken($base-color, 20%);
    border-bottom: 3px solid $base-color;
    @include fontsize(30);
    @media #{$small}{
      @include fontsize(25);
    }
  }

  .serviceEvaluationArea {
    background-color: lighten($base_color_2, 2%);
    @include border-radius(5px);
    padding: 10px;
    margin-bottom: 20px;
  }

}


h3, h2 {
  &.topService {
    border: 2px solid darken($base-color, 30%);
    @include border-radius(5px);
    padding: 5px;
    @include fontsize(30);
    @media screen and (min-width: 768px) {
      @include fontsize(40);
    }
    a {
      color: darken($base-color, 30%);
      display: block;
    }
  }
}


h3.serviceDetailTitleH3 {
  border-bottom: $base-color 1px solid;
  color: $base-color_4;
  @include fontsize(25);
  @media screen and (min-width: 768px) {
    @include fontsize(35);
  }
  small {
    @media #{$small}{
      display: block;
      margin: 5px 5px 5px 20px;
    }
  }
}




.serviceReview {

  h3 {
    background-color: darken($base-color, 20%);
    color: #FFFFFF;
    padding: 5px 10px;
    @include border-radius(3px);
    @include fontsize(30);
    @media #{$small}{
      @include fontsize(18);
    }
    i {
      font-size: 150%;
    }
    small {
      color: #FFFFFF;
      margin-left: 30px;
      @media #{$small}{
        display: block;
        margin: 5px 23px;
        @include fontsize(16);
      }
    }
  }

  ul {
    &.commentList {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        list-style: none;
        background-color: #f7f7f7;
        padding: 10px;
        margin-bottom: 10px;
        @include border-radius(10px);
        dl.commentBlock {
          margin-bottom: 0;
          dt.commenterName {
            font-weight: normal;
            border-bottom: 1px dotted darken($base-color, 10%);
            @include fontsize(22);
            @media #{$small}{
              @include fontsize(18);
            }
            i {
              font-size: 160%;
              color: darken($base-color, 10%);
            }
            span {
              font-size: 80%;
              @media #{$large}{
                float: right;
              }
              @media #{$middle}{
                float: right;
              }
              @media #{$small}{
                display: block;
                margin-top: 3px;
              }
            }
          }
          dd.commenterContent {
            background-color: #FFF;
            padding: 5px 0 0 0;
            margin: 0;
            @include fontsize(22);
            @media #{$small}{
              @include fontsize(18);
            }
            .commenterDetailWell {
              margin: 5px;
              background-color: #FFF;
            }
            .commenterDetail {
              border-bottom: 1px dotted darken($base-color, 10%);
              dl {
                margin-bottom :0;
                dt, dd {
                  padding-top: 3px;
                  padding-bottom: 3px;
                  margin-bottom :0;
                  @include fontsize(19);
                  @media #{$small}{
                    @include fontsize(16);
                  }
                }
              }
            }
            .commentServiceNameArea {
              text-align: right;
              span {
                &.commentServiceName {
                  display: inline-block;
                  margin: 3px 5px 5px 0;
                  padding: 2px 10px;
                  background-color: darken($base-color, 10%);
                  color: #ffffff;
                  @include fontsize(18);
                  @media #{$small}{
                    @include fontsize(16);
                  }
                }
              }
            }
            h5 {
              color: darken($base-color, 10%);
              font-weight: bold;
              margin: 0 0 10px 0;
              @include fontsize(26);
              @media #{$small}{
                @include fontsize(20);
              }
            }
            .commentContentArea {
              padding: 10px;
            }
          }
        }
      }
    }
  }
}


#reviewForm {
  border: 1px solid #DDDDDD;
  padding: 30px;
  @media #{$small}{
    padding: 10px 10px;
  }
  h3 {
    display: none;
  }
  h5 {
    margin: 10px 0 5px 0;
    font-weight: 700;
    border-bottom: 1px dotted $base-color;
    &:before {
      font-weight: normal;
      color: $base-color;
      content: "\f046";
      font-family: FontAwesome;
      padding-right: 10px;
    }
    @include fontsize(24);
    @media #{$small}{
      @include fontsize(20);
    }
  }
  h6 {
    font-weight: 600;
    @include fontsize(20);
    @media #{$small}{
      @include fontsize(18);
    }
  }
  ul {
    margin: 0;
    padding: 0;
    &.commentAnswer {
      li {
        width: 18%;
        display: inline-block;
        @include fontsize(20);
        @media #{$small}{
          width: 33%;
          @include fontsize(18);
        }
      }
    }
  }
  label {
    &.radioBlock {
      font-weight: normal;
    }
    &:not(.radioBlock) {
      display: block;
      margin: 10px 0 5px 0;
      font-weight: 700;
      border-bottom: 1px dotted $base-color;
      &:before {
        font-weight: normal;
        color: $base-color;
        content: "\f046";
        font-family: FontAwesome;
        padding-right: 10px;
      }
      @include fontsize(24);
      @media #{$small}{
        @include fontsize(20);
      }
    }
  }
  textarea {
    width: 100%;
    height: 100px;
    padding: 6px 12px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  }
  input[type=text]{
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    margin-bottom: 10px;
  }

  .form-submit {
    text-align: center;
  }
  input[type=submit]{
    margin: 10px auto;
    font-weight: bold;
    background-color: $base-color;
    color: #fff;
    border-style: none;
    width: 80%;
    padding: 10px 30px;
    @media #{$small}{
      width: 100%;
    }
  }

  .commentGuideLine {
    @include fontsize(18);
    @media #{$small}{
      @include fontsize(16);
    }
  }


}





.featureBlock {
  ul {
    &.featureLists {
      li {
        a {
          color: $base-color_4;
          border-bottom: 1px dotted $base-color_4;
          display: block;
          &:before {
            content: " \f0a9  ";
            font-family: FontAwesome;
          }
        }
      }
    }
  }
}




table {
  &.serviceDetailTable {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    line-height: 1.5;
    border-top: 1px solid #ccc;
    //border-left: 3px solid darken(#aaaaaa, 35%);
    @include fontsize(22);
    @media #{$small}{
      @include fontsize(18);
    }
    tr {
      &:first-child {
        th {
          border-top: 3px solid #649a83;
        }
        td {
          border-top: 3px solid #d8d8d8;
        }
      }
    }
    th, td {
      padding: 7px;
      vertical-align: top;
    }
    th {
      font-weight: bold;
      vertical-align: top;
      color: #333333;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      background-color: #f3f3f3;
      width: 25%;
      @media #{$small}{
        width: 30%;
      }
    }
    td {
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      width: 75%;
      @media #{$small}{
        width: 70%;
      }
      ul {
        &.componentList {
          margin: 0;
          li {
            display: inline-block;
            margin-right: 10px;
            a {
              color: darken($base-color, 15%);
              border-bottom: 1px dotted $base_color_2;
              &:before {
                content: " \f0fe  ";
                font-family: FontAwesome;
              }
            }
          }
        }
      }
      h4 {
        @include fontsize(26);
        border-bottom: 1px solid #555555;
        &:before {
          content: " \f05d  ";
          font-family: FontAwesome;
        }
        @media #{$small}{
          @include fontsize(22);
        }
      }
      h5 {
        @include fontsize(24);
        &:before {
          content: " \f111  ";
          font-family: FontAwesome;
          color: #777777;
        }
        @media #{$small}{
          @include fontsize(20);
        }
      }
    }
  }
}



.operationCommentArea {
  h3 {
    background-color: darken($base-color_4, 0%);
    color: #FFFFFF;
    padding: 5px 10px;
    @include border-radius(3px);
    @include fontsize(30);
    @media #{$small}{
      @include fontsize(26);
    }
    i {
      font-size: 150%;
    }
    small {
      color: #FFFFFF;
      margin-left: 30px;
      @media #{$small}{
        display: block;
        margin: 5px 23px;
        @include fontsize(20);
      }
    }
  }
  h4 {
    font-weight: bold;
    margin: 1.2em 0 0.5em 0.5em;
    border-bottom: 2px solid #666666;
    @include fontsize(28);
    @media #{$small}{
      @include fontsize(22);
    }
    i {
      color: $base-color_4;
    }
  }
  .operationComment {
    padding: 10px;
    background-color: #fbfbfb;
    h5 {
      font-weight: bold;
      margin: 1em 0 0.5em 0.5em;
      @include fontsize(26);
      @media #{$small}{
        @include fontsize(20);
      }
      &:before {
        content: " \f111  ";
        font-family: FontAwesome;
        color: $base-color_4;
      }
    }
  }
}


