

.photoLibrary {
  margin-bottom: 10px;
  @media (min-width: 768px) {
    margin-bottom: 15px;
  }
  a {
    text-align: center;
    display: inline-block;
    width: 20%;
    padding: 2px;
    img {
      width: 100%;
      height: auto;
      @media (min-width: 768px) {
        max-width: 150px;
      }
    }
  }
}
