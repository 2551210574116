//wordpress Editor START
p {
  display: block;
  margin: 1em 0;
}
strong { font-weight: bold; }
em { font-style: italic; }
blockquote {
  background-color: #f7f7f7;
  display: block;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 40px;
  -webkit-margin-end: 40px;
}
/* img */
.aligncenter {
  display: block;
  margin: 0 auto;
}
.alignright { float: right; }
.alignleft { float: left; }

img[class*="wp-image-"],
img[class*="attachment-"] {
  height: auto;
  max-width: 100%;
}
/* clearfix */
.clearfix {
  overflow: hidden;
  zoom: 1;
}
.clearfix:after {
  content: "";
  display: block;
  clear: both;
}
//wordpress Editor END
