
.clearfix:after {
  content:" ";
  display:block;
  clear:both;
}

/** margin bottom **/
@for $i from 1 through 8 {
  .mb#{$i*5} {
    margin-bottom: #{$i*5}px;
  }
  .mt#{$i*5} {
    margin-top: #{$i*5}px;
  }
}

/* Bootstrapのガター幅を調整するための汎用CSS */
@for $i from 0 through 5 {
  .row-#{$i*10} > div {
    padding-right: #{$i*5}px;
    padding-left: #{$i*5}px;
  }
}



ul {
  padding: 0;
  li {
    padding: 0;
    list-style: none;
  }
}


