.punkuzu {
  background-color: #eee;
  padding: 15px 0 5px;
  margin-top: 0;
  @media #{$middle} {
    margin-top: 96px;
  }
  @media #{$large} {
    margin-top: 96px;
  }
}
