section.eyeCatch {
  background-color: #fff;

  @media #{$middle} {
    margin-top: 95px;
  }
  @media #{$large} {
    margin-top: 95px;
  }

  .topEyeCatch {
    background-image: url("images/icatch_sp01.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    @media #{$middle} {
      background-image: url("images/icatch01_md.png");
      background-position: center;
      background-size: cover;
      position: relative;
      height: 325px;
    }
    @media #{$large} {
      //margin-top: 95px;
      background-image: url("images/icatch01.png");
      background-position: center;
      background-size: cover;
      position: relative;
      height: 394px;
    }
    .topEyeCatchText {
      margin-top: 150px;
      @media #{$middle} {
        margin-top: 170px;
      }
      @media #{$large} {
        margin-top: 200px;
      }
      p {
        background-color: #fff600;
        font-weight: bold;
        color: #000;
        text-align: center;
        @include fontsize(18);
        @media #{$middle} {
          background-color: transparent;
          @include fontsize(30);
          text-align: left;
        }
        @media #{$large} {
          background-color: transparent;
          @include fontsize(40);
          text-align: left;
        }
        span{
          color: #ff0101;
          @media #{$middle} {
            background-color: #fff600;
          }
          @media #{$large} {
            background-color: #fff600;
          }
        }
      }
    }
    .btnTopLink {
      background-color: #ff5a00;
      border-radius: 4px;
      color: #fff;
      box-shadow: 0 3px 0 #ae3e01;
      text-shadow: 0 1px 1px rgba(0, 0, 0, .4);
      font-weight: bold;
      margin-bottom: 20px;
      @include fontsize(20);
      @media #{$middle} {
        line-height: 40px;
        @include fontsize(30);
      }
      @media #{$large} {
        line-height: 52px;
        @include fontsize(40);
      }
      i {
        @media #{$middle} {
          margin-left: 20px;
        }
        @media #{$large} {
          margin-left: 20px;
        }
      }
    }
  }

  //@media #{$small} {
  //  margin-top: 0px;
  //  .icatch_pc{
  //    background-image: url("images/icatch_sp01.png");
  //    background-position: center;
  //    background-size:cover;
  //    background-repeat: no-repeat;
  //    text-align: center;
  //    padding: 100px 0px 30px;
  //    p{
  //
  //      margin: 50px auto 20px;
  //
  //
  //      @include fontsize(18);
  //      font-weight: bold;
  //      color: #000;
  //      background-color: yellow;
  //      text-align: center;
  //
  //      span{
  //        color: red;
  //      }
  //    }
  //    .sl_button {
  //
  //      background-color: #ff5a00;
  //      border-radius: 4px;
  //      color: #fff;
  //      line-height: 52px;
  //      -webkit-transition: none;
  //      transition: none;
  //      box-shadow: 0 3px 0 #ae3e01;
  //      text-shadow: 0 1px 1px rgba(0, 0, 0, .4);
  //      padding: 10px 10px;
  //
  //
  //      @include fontsize(20);
  //      font-weight: bold;
  //      i{
  //        margin-left: 10px;
  //        display: none;
  //      }
  //    }
  //
  //  }
  //
  //}
  //
  //@media #{$middle} {
  //  margin-top: 95px;
  //  .icatch_pc{
  //    background-image: url("images/icatch01_md.png");
  //    background-position: center;
  //    background-size: cover;
  //    position: relative;
  //    height: 325px;
  //    p{
  //      position: absolute;
  //      margin: 0 0;
  //      top:170px;
  //      left:380px;
  //      @include fontsize(30);
  //      font-weight: bold;
  //      color: #000;
  //      span{
  //        background-color: #fff600;
  //        color: #ff0101;
  //      }
  //    }
  //    .sl_button {
  //      position: relative;
  //      background-color: #ff5a00;
  //      border-radius: 4px;
  //      color: #fff;
  //      line-height: 52px;
  //      -webkit-transition: none;
  //      transition: none;
  //      box-shadow: 0 3px 0 #ae3e01;
  //      text-shadow: 0 1px 1px rgba(0, 0, 0, .4);
  //      padding: 10px 30px;
  //      top:240px;
  //      left:380px;
  //      @include fontsize(40);
  //      font-weight: bold;
  //      i{
  //        margin-left: 20px;
  //      }
  //    }
  //    .sl_button:hover {
  //
  //      top:236px;
  //      box-shadow: 0 7px 0 #ae3e01;
  //      text-decoration: none;
  //    }
  //    .sl_button:active {
  //      top:243px;
  //      box-shadow: none;
  //    }
  //  }
  //
  //}
  //@media #{$large} {
  //  margin-top: 95px;
  //  .icatch_pc{
  //    background-image: url("images/icatch01.png");
  //    background-position: center;
  //    background-size: cover;
  //    position: relative;
  //    height: 394px;
  //    p{
  //      position: absolute;
  //      margin: 0 0;
  //      top:200px;
  //      left:440px;
  //      @include fontsize(40);
  //      font-weight: bold;
  //      color: #000;
  //      span{
  //        background-color: #fff600;
  //        color: #ff0101;
  //      }
  //    }
  //    .sl_button {
  //      position: relative;
  //      background-color: #ff5a00;
  //      border-radius: 4px;
  //      color: #fff;
  //      line-height: 52px;
  //      -webkit-transition: none;
  //      transition: none;
  //      box-shadow: 0 3px 0 #ae3e01;
  //      text-shadow: 0 1px 1px rgba(0, 0, 0, .4);
  //      padding: 10px 60px;
  //      top:300px;
  //      left:440px;
  //      @include fontsize(40);
  //      font-weight: bold;
  //      i{
  //        margin-left: 20px;
  //      }
  //    }
  //    .sl_button:hover {
  //
  //      top:296px;
  //      box-shadow: 0 7px 0 #ae3e01;
  //      text-decoration: none;
  //    }
  //    .sl_button:active {
  //      top:303px;
  //      box-shadow: none;
  //    }
  //
  //  }
  //}


  .icatch_info{

  }
  p.icatch_info_ttl{
    text-align: center;
    background-color: #000;
    padding: 10px 0;
    margin: 0;
    @include fontsize(40);
    @media #{$small} {
      @include fontsize(26);
    }
    font-weight: bold;
    color: #fceb93;

  }
  

}

