/** 用語集 **/
.glossarySearch {
  padding: 20px;
  border: 1px solid #DDDDDD;
  @include border-radius(10px);
  background-color: #EEEEEE;
  .form-group {
    margin-bottom: 0;
  }
}
.glossaryLineList {
  a {
    @media #{$small}{
      padding: 7px;
      @include fontsize(16);
    }
  }
}
.glossaryList {
  margin: 20px 0 30px;
  padding: 0;
  li {
    list-style: none;
    border-bottom: 1px dotted #DDDDDD;
    a {
      display: block;
      padding: 10px;
      @media #{$small}{
        @include fontsize(16);
      }
      &:hover {
        background-color: lighten($base-color_4, 65%);
      }
      i {
        color: $base-color_4;
      }
    }
  }
}

h3 {
  &.glossaryDetailRecommendTitle {
    padding: 10px;
    background-color: #EEEEEE;
    border-bottom: 1px solid #CCCCCC;
    font-weight: bold;
    @include fontsize(23);
    @media #{$middle}{
      @include fontsize(26);
    }
    @media #{$large}{
      @include fontsize(26);
    }
  }
}

