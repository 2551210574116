.btnServiceDetail, .btnServiceLink {
  @media #{$small}{
    @include fontsize(18);
  }
}

.btnServiceLink {
  background-color: $base-color-orange;
  &:hover {
    background-color: darken($base-color-orange, 5%);
  }
}


.btnReviewOpen {
  background-color: $base-color_2;
  color: darken($base-color, 20%);
}


.btnReviewAll {
  background-color: lighten($base-color_4, 30%);
  color: #FFFFFF;
}

.btnReviewHide {

}


.btnGlossaryDefault {
  background-color: lighten($base-color, 10%);
}

