.top_contents{
  p.sentence{
    color: #000;
    text-align: center;
    @include fontsize(36);
    @media #{$middle} {
      @include fontsize(26);
    }
    @media #{$small} {
      @include fontsize(26);
      text-align: left;
    }
    span {
      font-weight: bold;
      color: #ff4800;
    }


  }
  p.grn_lead{
    color: #007551;
    text-align: center;
    @include fontsize(48);
    @media #{$middle} {
      @include fontsize(36);
    }
    @media #{$small} {
      @include fontsize(30);
    }
    font-weight: bold;
  }

  p.sentence_lg{
    color: #000;
    text-align: center;
    @include fontsize(52);
    @media #{$middle} {
      @include fontsize(40);
    }
    @media #{$small} {
      @include fontsize(28);
      text-align: left;
    }
    span {
      font-weight: bold;
      color: #ff4800;
    }

  }

  p.yel_line{

    @include fontsize(36);
    @media #{$middle} {
      @include fontsize(30);
    }
    @media #{$small} {
      @include fontsize(28);
      text-align: left;
    }
    font-weight: bold;
    color: #000;
    text-align: center;
    span{
      background: linear-gradient(transparent 50%, yellow 50%);
    }
  }

  ul{
    border: 5px solid red;
    padding: 20px;
    li{
      list-style: disc;
      @include fontsize(48);
      @media #{$middle} {
        @include fontsize(36);
      }
      @media #{$small} {
        @include fontsize(26);
        margin-left: 20px;
      }
      color: #000;
      font-weight: bold;
      margin-left: 60px;
      padding-bottom: 10px;
      span{
        background: linear-gradient(transparent 50%, yellow 50%);
      }

    }
  }

  dl{
    border: 1px solid #007551;
    padding: 20px;
    dt{
      @include fontsize(52);
      @media #{$middle} {
        @include fontsize(40);
      }
      @media #{$small} {
        @include fontsize(26);
      }

      font-weight: bold;
      color: #ff4800;

    }
    dd{
      color: #000;
      @include fontsize(36);
      @media #{$middle} {
        @include fontsize(26);
      }
      @media #{$small} {
        @include fontsize(26);
      }

    }
  }
}

