

.mainContent {

  ul {
    li {
      border-bottom: 1px dotted #DDDDDD;
      margin: 5px 0;
      &:before {
        color: #FF0000;
        content: " \f00c  ";
        font-family: FontAwesome;
      }
    }
    &.toc_list{
      li {
        border: none;
        &:before {
          content: "";
        }
      }
    }
  }

  ol {
    li {
      margin: 5px 0;
      border-bottom: 1px dotted #DDDDDD;
    }
  }


  h1 {
    padding-bottom: 5px;
    border-bottom: 1px solid #ccc;
    @include fontsize(35);
    @media #{$small}{
      @include fontsize(20);
      margin: 0;
    }
    @media #{$middle}{
      @include fontsize(30);
    }

  }
  h2 {

    border-top: 1px solid darken($base_color, 20%);
    border-bottom: 1px solid darken($base_color, 20%);
    padding: 5px 10px;
    background-color: lighten($base-color, 30%);
    font-weight: bold;
    line-height: 120%;
    @include fontsize(33);
    margin-top: 30px;
    @media #{$middle}{
      @include fontsize(30);
      margin-top: 0;
    }
    @media #{$small}{
      @include fontsize(26);
    }
  }
  h3 {
    position: relative;
    padding-bottom: 5px;
    border-bottom: 4px solid #ccc;
    font-weight: bold;
    line-height: 120%;
    margin-bottom: 10px;
    margin-top: 30px;
    @include fontsize(33);
    @media #{$small}{
      @include fontsize(24);
    }
    @media #{$middle}{
      @include fontsize(28);
    }
    a {
      color: #000000;
    }
  }



  h3::after {
    position: absolute;
    bottom: -4px;
    left: 0;
    z-index: 2;
    content: '';
    width: 20%;
    height: 4px;
    background-color: darken($base-color, 30%);
  }
  h4 {
    background-color: lighten($base-color_4, 80%);
    padding: .5em .25em .5em .75em;
    border-left: 6px solid darken($base-color, 30%);
    border-bottom: 1px solid darken($base-color, 30%);
    font-weight: bold;
    line-height: 120%;
    @include fontsize(26);
    @media #{$small}{
      @include fontsize(23);
    }
    @media #{$middle}{
      @include fontsize(26);
    }
  }


  h5 {
    color: #1a1a1a;
    padding: 0 0 10px;
    margin: 20px 0 20px;
    font-weight: bold;
    border-bottom: 3px solid darken($base-color, 30%);
    line-height: 120%;
    @include fontsize(22);
    @media #{$small}{
      padding: 0;
      margin-bottom: 5px;
    }
  }

  img {
    margin: 10px;
    @media #{$small}{
      margin: 10px 0;
    }
  }

  p {
    line-height: 200%;
    margin-bottom: 10px;
    @include fontsize(22);
    @media #{$small}{
      @include fontsize(20);
    }
    @media #{$middle}{
      @include fontsize(23);
    }
  }

  /** table **/
  table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    line-height: 1.5;
    border-top: 1px solid #ccc;
    //border-left: 3px solid darken(#aaaaaa, 35%);
    border-left: 3px solid darken($base-color, 30%);
    @include fontsize(22);
    @media #{$small}{
      @include fontsize(18);
    }
    tr {
      @media #{$small}{
        //display: block;
      }
    }
    th {
      width: 25%;
      padding: 10px;
      font-weight: bold;
      vertical-align: top;
      color: #333333;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      background-color: #f3f3f3;
      @media #{$small}{
        padding: 5px;
        //display:block;
        width: auto;
      }
    }
    td {
      width: 75%;
      padding: 10px;
      vertical-align: top;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      @media #{$small}{
        padding: 5px;
        //display: block;
        width: auto;
      }
      h4 {
        margin-top: 5px;
        margin-bottom: 0;
        font-weight: bold;
        border: none;
        background-color: #FFF;
        padding: 5px;
        @include fontsize(22);
        @media #{$small}{
          @include fontsize(20);
        }
        &:before {
          color: darken($base-color, 20%);
          content: " \f111  ";
          font-family: FontAwesome;
        }
      }
      h5 {
        border: none;
        margin: 5px 0;
        @include fontsize(22);
        @media #{$small}{
          @include fontsize(20);
        }
      }
      p {
        margin-top: 0;
        margin-bottom: 10px;
      }

      ul {
        margin: 0;
        li {
          margin: 3px 5px;
          border-bottom: 1px dotted #DDDDDD;
          display: inline-block;
        }
      }

    }
  }



  //wordpress Editor START
  p {
    display: block;
    margin: 1em 0;
  }
  strong { font-weight: bold; }
  em { font-style: italic; }
  blockquote {
    background-color: #f7f7f7;
    display: block;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 40px;
    -webkit-margin-end: 40px;
  }
  /* img */
  .aligncenter {
    display: block;
    margin: 0 auto;
  }
  .alignright { float: right; }
  .alignleft { float: left; }

  img[class*="wp-image-"],
  img[class*="attachment-"] {
    height: auto;
    max-width: 100%;
  }
  /* clearfix */
  .clearfix {
    overflow: hidden;
    zoom: 1;
  }
  .clearfix:after {
    content: "";
    display: block;
    clear: both;
  }
  //wordpress Editor END

}







.wellIndex {
  a {
    color: #0A246A;
  }
}


h4 {
  &.commentTitle {
    color: #42ccf1;
    font-weight: bold;
    @include fontsize(30);
    @media #{$small}{
      @include fontsize(25);
    }
    i {
      @include fontsize(50);
      margin-right: 5px;
      @media #{$small}{
        @include fontsize(35);
      }
    }
    small {
      margin-left: 10px;
      font-size: 70%;
      @media #{$small}{
        margin: 5px 0 0 10px;
        display: block;
      }
    }
  }
}
.wellComment {
  border: 1px solid #dcfaff;
  background-color: #ECFEFF;
  line-height: 150%;
  @include fontsize(20);
  @media #{$small}{
    @include fontsize(17);
  }
}



//写真館
.imageLibrary {
  padding: 3px;
  border: 1px solid #DDDDDD;
  margin-bottom: 10px;
  @media (min-width: 768px) {
    margin-bottom: 15px;
  }
  a {
    text-align: center;
    display: inline-block;
    border: 1px solid #dddddd;
    padding: 2px;
    width: 12.5%;
    @media #{$small}{
      width: 16.5%;
    }
    img {
      width: 100%;
      height: auto;
      @media (min-width: 768px) {
        max-width: 150px;
      }
    }
  }
}




// コラムカテゴリー
ul {
  &.columnCategoryLink {
    padding: 0;
    li {
      display: inline-block;
      margin-right: 10px;
      @include fontsize(18);
      @media #{$middle}{
        @include fontsize(23);
      }
      @media #{$large}{
        @include fontsize(23);
      }
      &:before {
        content: " \f07c ";
        font-family: FontAwesome;
        color: #555555;
      }
      a {
        color: #555555;
      }
    }
  }
}

// コラム関連記事
.recommendColumn {
  h3 {
    padding: 10px;
    background-color: #EEEEEE;
    border-bottom: 1px solid #CCCCCC;
    font-weight: bold;
    @include fontsize(23);
    @media #{$middle}{
      @include fontsize(26);
    }
    @media #{$large}{
      @include fontsize(26);
    }
  }
  ul {
    &.recommendColumnLists {
      list-style: none;
      padding: 0;
      margin: 0 0 20px 0;
      li {
        //margin: 10px 0 10px 0;
        padding: 10px 0 10px 0;
        border-bottom: 1px solid #DDDDDD;
        h5 {
          font-weight: bold;
          a {
            color: #222222;
          }
        }
        p {
          @include fontsize(20);
        }
        img {
          padding: 3px;
          border: 1px solid #DDDDDD;
        }
      }
    }
  }
}



// 特集関連記事
.recommendFeature {
  h3 {
    padding: 10px;
    background-color: #EEEEEE;
    border-bottom: 1px solid #CCCCCC;
    font-weight: bold;
    @include fontsize(23);
    @media #{$middle}{
      @include fontsize(26);
    }
    @media #{$large}{
      @include fontsize(26);
    }
  }
  ul {
    &.recommendFeatureLists {
      list-style: none;
      padding: 0;
      margin: 0 0 20px 0;
      li {
        //margin: 10px 0 10px 0;
        padding: 10px 0 10px 0;
        border-bottom: 1px solid #DDDDDD;
        h5 {
          font-weight: bold;
          a {
            color: #222222;
          }
        }
        p {
          @include fontsize(20);
        }
        img {
          padding: 3px;
          border: 1px solid #DDDDDD;
        }
      }
    }
  }
}




h3.serviceListTitle {
  border-left: 5px solid #649a83;
  background-color: #EEEEEE;
  margin: 0 0 5px 0;
  @include fontsize(34);
  @media #{$small}{
    @include fontsize(22);
  }
  a {
    padding: 10px;
    display: block;
    color: #21332b;
    @media #{$small}{
      padding: 5px;
    }
    small {
      @media #{$large}{
        margin-top: 5px;
        margin-right: 10px;
        float: right;
      }
      @media #{$middle}{
        margin-top: 5px;
        margin-right: 10px;
        float: right;
      }
      @media #{$small}{
        margin-top: 5px;
        display: block;
      }
    }
  }
  &:hover {
    background-color: #DDDDDD;
  }
}
