

aside {
  h2 {
    background-color: #000000;
    color: #FFFFFF;
    padding: 10px 10px;
    //margin: 0;
    @include fontsize(22);
    @media screen and (min-width: 768px) {
      @include fontsize(26);
    }
  }


  h4 {
    @include fontsize(30);
    border-left: $base-color 5px solid;
    background-color: lighten($base-color, 20%);
    margin: 0;
    a {
      display: block;
      padding: 8px 5px;
      color: #000;
      small {
        font-size: 90%;
        color: #FFF;
        background-color: #007551;
        padding: 3px 5px;
        @include border-radius(5px);
      }
    }
  }

  p {
    &.sideServiceText {
      margin-top: 0;
    }
  }

  .sideServicePoint {
    @include fontsize(28);
    margin: 3px 0;
    b {
      background-color: #007551;
      display: inline-block;
      color: #FFF;
      padding: 3px 10px;
    }
    span {
      display: inline-block;
      text-align: center;
    }

    dl {
      margin-bottom: 0;
      dt, dd {
        text-align: center;
        border-top: 1px solid #007551;
        border-bottom: 1px solid #007551;
      }
      dt {
        background-color: #007551;
        color: #FFF;
        width: 50%;
        float: left;
        clear: both;
      }
      dd {
        font-weight: bold;
        color: #007551;
        border-right: 1px solid #007551;
      }
    }
  }

  dl {
    &.sideServicePoint {
      text-align: center;
      margin-top: 5px;
      margin-bottom: 5px;
      dt {
        border-bottom: 1px solid #007551;
        span {
          padding: 5px 0;
          display: block;
          background: #007551;
          color: #FFF;
        }
      }
      dd {
        border-bottom: 1px solid #007551;
        span {
          display: block;
          padding: 5px 0;
        }
      }
    }
  }

  .sideService {

    h5 {
      @include fontsize(30);
      border-left: darken($base-color, 20%) 7px solid;
      background-color: lighten($base-color, 30%);
      padding: 8px;
      margin: 0;
    }

    border-bottom: 1px solid #DDD;
    p {
      @include fontsize(18);
    }
  }


  .sideColumnCategory {
    padding: 0 10px;
    li {
      list-style: none;
      padding: 8px 0;
      border-bottom: 1px dotted #DDDDDD;
      &:last-child {
        border-bottom: none;
      }
      a {
        display: block;
        color: #686868;;
      }
    }
  }



  .sideBlock {
    @media #{$large}{
      border: 1px solid lighten($base-color, 20%);
      padding: 0 5px;
    }
  }


  ul {
    &.sideArticleList {
      li {
        &:last-child {
          .sideColumnBox{
            @media #{$large}{
              border-bottom: none;
            }
          }
        }
      }
    }
  }


  .sideColumnBox{
    &:hover {
      background-color: lighten($base-color, 30%);
    }
    @media #{$small}{
      border-bottom: 1px solid lighten($base-color, 10%);
      padding: 5px 0 5px 0;
    }
    @media #{$middle}{
      border: 1px solid $base_color;
      @include border-radius(5px);
      padding: 5px;
    }
    @media #{$large}{
      border-bottom: 1px solid lighten($base-color, 20%);
      padding: 5px 0 5px 0;
    }

    &::after {
      content:" ";
      display:block;
      clear:both;
    }
    a {
      display: block;
      figure {
        float: left;
        //display: inline-block;
        //width: 40%;
        img {
          &.sideColumnImage {
            max-width: 90px;
            margin: 0 10px 0 0;
          }
        }
      }
      dl {
        //display: inline-block;
        margin: 0;
        //width: 55%;
        dt {
          color: lighten(#000000, 30%);
          @include fontsize(20);
          //font-weight: normal;
        }
        dd {
          @include fontsize(18);
          color: lighten(#000000, 20%);
        }
      }
    }
  }

}

