.footer {
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: auto;
  background-color: #333;

  p {
    &.text-muted {
      padding-bottom: 30px;
      border-bottom: 1px solid #000;
      color: #CCCCCC;
      margin-bottom: 0;
      @media #{$large} {
        margin-bottom: 30px;
      }
    }
    &.copyright {
      text-align: center;
      color: #ccc;
      padding-top: 20px;
      margin-bottom: 30px;
      a {
        color: #ccc;
      }
    }
  }
  ul {
    padding: 0;
    li {
      border-bottom: 1px solid #000;
      padding: 5px 10px;
      @media #{$large} {
        display: inline-block;
        margin-right: 20px;
        margin-bottom: 30px;
        border-bottom: none;
      }
      a {
        color: #ccc;
        &:hover {
          color: #ccc;
          text-decoration: underline;
        }
      }
    }
  }



}


