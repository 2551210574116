section {
  &.frontPage {
    h2 {
      position: relative;
      padding: .75em 1em .75em 1.5em;
      border: 1px solid #ccc;
      &:after {
        position: absolute;
        top: .5em;
        left: .5em;
        content: '';
        width: 6px;
        height: -webkit-calc(100% - 1em);
        height: calc(100% - 1em);
        background-color: $base-color_4;
        border-radius: 4px;
      }
      @include fontsize(22);
      @media #{$middle} {
        @include fontsize(27);
      }
      @media #{$large} {
        @include fontsize(30);
      }
    }
    h3 {
      padding: .5em .75em;
      background-color: #f6f6f6;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      &:before {
        content: "\f046";
        font-family: FontAwesome;
      }
      @include fontsize(20);
      @media #{$middle} {
        @include fontsize(25);
      }
      @media #{$large} {
        @include fontsize(28);
      }
    }
    h4 {
      @media #{$middle} {
      }
      @media #{$large} {
      }
    }
    .imageFloatRight {
      @media #{$small} {
        margin: 5px auto;
        display: block;
        width: 250px;
      }
      @media #{$middle} {
        float:right;
        margin: 0 0 10px 10px;
        max-width: 400px;
      }
      @media #{$large} {
        float:right;
        margin: 0 0 10px 10px;
        max-width: 400px;
      }
    }
    .imageFloatLeft {
      @media #{$small} {
        margin: 5px auto;
        display: block;
        width: 250px;
      }
      @media #{$middle} {
        float:left;
        margin: 0 10px 10px 0;
        max-width: 400px;
      }
      @media #{$large} {
        float:left;
        margin: 0 10px 10px 0;
        max-width: 400px;
      }
    }
    .wd200 {
      max-width: 200px;
    }

    p {
      line-height: 180%;
      @include fontsize(22);
      margin: 0.5em 0;
      @media #{$middle} {
        @include fontsize(22);
      }
      @media #{$large} {
        @include fontsize(24);
      }
    }
    span, b, strong {
      font-size: 110%;
    }
    .red {
      color: #FF0000;
    }
  }

  &.frontPageService {


    h3 {
      border-left: 5px solid #649a83;
      background-color: #EEEEEE;
      margin: 0 0 5px 0;
      @include fontsize(34);
      @media #{$small}{
        @include fontsize(22);
      }
      a {
        padding: 10px;
        display: block;
        color: #21332b;
        @media #{$small}{
          padding: 5px;
        }
        small {
          font-size: 90%;
          color: #FFF;
          background-color: #007551;
          padding: 5px 10px 2px 10px;
          @include border-radius(5px);
          @media #{$large}{
            margin-top: 5px;
            margin-right: 10px;
            float: right;
          }
          @media #{$middle}{
            margin-top: 5px;
            margin-right: 10px;
            float: right;
          }
          @media #{$small}{
            //margin-top: 5px;
            float: right;
            padding: 2px 5px 1px 5px;
            //display: block;
          }
        }
      }
      &:hover {
        background-color: #DDDDDD;
      }
    }



  }

}



h2 {
  &.frontPageTitle {
    position: relative;
    padding: .75em 1em .75em 1.5em;
    border: 1px solid #ccc;
    @include fontsize(34);
    @media #{$small}{
      @include fontsize(22);
    }
    &:after {
      position: absolute;
      top: .5em;
      left: .5em;
      content: '';
      width: 6px;
      height: -webkit-calc(100% - 1em);
      height: calc(100% - 1em);
      background-color: darken($base-color, 15%);
      border-radius: 4px;
    }
  }

  &.topTitle {
    @include fontsize(30);
    color: #333333;
    padding: 0 0 10px;
    margin: 20px 0 20px;
    font-weight: bold;
    border-bottom: 3px solid #726c70;
    @media screen and (min-width: 768px) {
      @include fontsize(40);
    }
  }

}



.serviceCheckBlock {
  border: 1px solid $base-color;
  h4 {
    background-color: $base-color;
    margin: 0;
    padding: 4px;
    text-align: center;
    color: #fff;
    @include fontsize(30);
    @media #{$small}{
      @include fontsize(22);
    }
  }

  ul {
    &.serviceCharacteristic {
      li {
        display: inline-block;
        margin: 5px 10px 3px 10px;
        padding: 1px 5px;
        background-color: lighten($base_color_2, 2%);
        color: darken($base-color, 40%);
        border: 1px dotted darken($base-color, 10%);
        @include border-radius(5px);
        @include fontsize(23);
        @media #{$small}{
          @include fontsize(18);
        }
      }
    }
    &.serviceDetailPoint {
      li {
        margin: 0;
        padding: 10px 0;
        background-color: $base-color_2;
        @media #{$small}{
          padding: 3px 0;
        }
        &:nth-child(even) {
          background-color: lighten($base-color_2, 4%);
        }
        dl {
          margin: 0;
          @include fontsize(23);
          @media #{$small}{
            @include fontsize(18);
          }
          dd {
            text-align: right;
          }
        }
      }
    }
  }

}


.frontCommentArea {
  background-color: #f6f6f6;
  @include border-radius(5px);
  padding: 10px;
  ul {
    &.fontComment {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        background-color: #FFFFFF;
        margin-bottom: 5px;
        padding: 5px;
        @include border-radius(5px);
        dl {
          &.frontCommentBlock {
            dt {
              border-bottom: 1px dotted lighten($base-color_4, 20%);
              font-weight: normal;
              @include fontsize(22);
              @media #{$small}{
                @include fontsize(16);
              }
              i {
                font-size: 160%;
                color: lighten($base-color_4, 10%);
              }
              span {
                color: #333333;
                &.commenterData {
                  font-size: 80%;
                  @media #{$small}{
                    display: block;
                  }
                }
                &.commentDate {
                  @media #{$large}{
                    @include fontsize(18);
                    float:right;
                  }
                  @media #{$middle}{
                    @include fontsize(18);
                    float:right;
                  }
                  @media #{$small}{
                    @include fontsize(16);
                    display: block;
                  }
                }
              }
            }
            dd {
              padding: 5px 0 0 0;
              margin: 0;
              @include fontsize(20);
              @media #{$small}{
                @include fontsize(17);
              }
              .serviceNameBlock {
                text-align: right;
                span {
                  &.serviceName {
                    display: inline-block;
                    margin: 3px 0 5px 0;
                    padding: 2px 10px;
                    background-color: $base-color_4;
                    color: #ffffff;
                    @include fontsize(18);
                    @media #{$small}{
                      @include fontsize(14);
                    }
                    a {
                      color: #ffffff;
                    }
                  }
                }
              }
              h5 {
                color: lighten($base-color_4, 10%);
                font-weight: bold;
                margin: 0 0 5px 0;
                @include fontsize(24);
                @media #{$small}{
                  @include fontsize(20);
                }
              }
            }
          }
        }
      }
    }
  }
}
ul {
  &.topArchive2 {
    list-style: none;
    margin: 0 0 10px 0;
    padding: 0;
    li {
      border-bottom: 1px dotted #1b4255;
      margin-bottom: 5px;
      padding: 15px 0 15px;
      @include fontsize(25);
      @media #{$small}{
        @include fontsize(20);
      }
      @media #{$middle}{
        @include fontsize(24);
      }
      dl {
        margin-bottom: 0;
      }
    }
  }
}



dl {
  &.topColumnListBlock {
    dt {
      border: 1px solid #aaaaaa;
      @include border-radius(3px);
      a {
        background-color: #eeeeee;
        display: block;
        padding: 3px 5px;
        color: #007551;
      }
    }
    dd {
      p {
        @include fontsize(23);
        @media #{$small}{
          @include fontsize(20);
        }
        @media #{$middle}{
          @include fontsize(22);
        }
      }
    }
  }
}


.topFeatureBox {
  border: 1px solid #dddddd;
  padding: 5px;
  dl {
    &.topFeatureList {
      dt {
        border-bottom: 3px solid #007551;
        a {
          display: block;
          color: #007551;
        }
      }
      dd {
        p {
          @include fontsize(23);
          @media #{$small}{
            @include fontsize(20);
          }
          @media #{$middle}{
            @include fontsize(22);
          }
        }
      }
    }
  }
}