/*
Theme Name: ikumouLab
*/
$small:  "only screen and (max-width:768px)";
$middle: "only screen and (min-width:768px) and (max-width:1108px)";
$large:  "only screen and (min-width:1108px)";


// それぞれのメディアクエリで下記を扱う
//
//@media #{$small} {
//  //640px以下
//}
//
//@media #{$middle} {
//  //641px〜1200pxまで
//}
//
//@media #{$large} {
//  //1200px以上
//}
//

// ベースの色を設定
// この色基準で濃くしたらり（darken）、薄くしたり（lighten）出来る
//$base-color: #649a83;
$base-color: #AAAAAA;
//$base-color_2: #DEFFDF;
$base-color_2: #EEEEEE;
//$base_color_3: #FF8D8A;
$base_color_3: #fbbe96;
//$base-color_4: #4B539C;
$base-color_4: #523926;

$base-color-orange: #ff6d39;

body{
  font-family: "メイリオ" , Meiryo , Avenir , "Open Sans" , "Helvetica Neue" , Helvetica , Arial , Verdana , Roboto , "游ゴシック" , "Yu Gothic" , "游ゴシック体" , "YuGothic" , "ヒラギノ角ゴ Pro W3" , "Hiragino Kaku Gothic Pro" , "Meiryo UI" , "ＭＳ Ｐゴシック" , "MS PGothic" , sans-serif;
}


// mixin 読み込み　よく使う関数なんかを格納
@import "mixin";

// それぞれのパーツ別でファイルを作成
@import "header";
@import "eye-catch";
@import "btn";
@import "footer";
@import "aside";
@import "common";
@import "bread-crumb";
@import "photo-library";
@import "pagination";
@import "wordpress-editor";
@import "archive";

@import "glossary";
@import "maini-area";
@import "front-page";
@import "service";
@import "comment-form";
@import "ttl";
@import "top_contents";


